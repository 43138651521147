<template>
  <v-row dense class=" pa-0 mr-">
    <v-col cols="12" class="px-0">
      <v-progress-linear
        class="px-0"
        height="30"
        rounded
        background-color="charcoal lighten-1"
        :color="getColor()"
        :value="proc"
      >
        <template v-slot:default="{ value }" class="px-2">
          <span :class="textSize + ' paper--text pl-2 text-left'">
            {{ name }}
          </span>
          <v-spacer />
          <span class="paper--text pr-1">
            {{ Math.round(value * 10) / 10 }}% of

            {{ weight }} {{ weight_unit }} dv
          </span>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  name: 'Nutrient',
  mixins: [util],
  data: () => ({}),

  props: {
    weight: {
      type: String,
      default: '0'
    },
    proc: {
      type: String,
      default: '0'
    },
    weight_unit: {
      type: String,
      default: 'mcg'
    },
    name: {
      type: String,
      default: 'NA'
    }
  },
  methods: {
    getColor() {
      if (this.proc >= 100) return 'progressActive darken-1'
      else if (this.proc >= 50) return 'gold darken-1'
      else if (this.proc >= 25) return 'bronze'
      else return 'charcoal lighten-3'
    }
  }
}
</script>
